/*
 * @Date: 2023-09-07 11:42:48
 */
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./plugins/flexble";
// import Vconsole from "vconsole";
import "./assets/styles/main.less";
import "./assets/styles/index.less";
import "vant/lib/index.css";
import { List, Image, Toast, Swipe, SwipeItem, Icon, Lazyload, Tab, Tabs, NavBar, BackTop, Loading, Overlay } from "vant";
import { SPACE_MAP } from "@/utils/constan";
import { Haa } from "@haa/fwk-h5/lib/index";
import "@haa/fwk-h5/lib/styles/index.css";
// import { getUrlParams } from "@/utils/common";
import WapInter from "@haa/fwk-h5/components/wap-inter.vue";
import WapNative from "@haa/fwk-h5/components/wap-native.vue";
import WapBanner from "@haa/fwk-h5/components/wap-banner.vue";
import loading from "@/components/common/loading.vue";
import ICP from "@/components/common/icp.vue";

// const params: AnyObject = getUrlParams(window.location.href);
// console.error(params, "main打印");
// const vConsole = new Vconsole();
const app = createApp(App);

app.use(List);
app.use(Image);
app.use(Toast);
app.use(Swipe);
app.use(SwipeItem);
app.use(Icon);
app.use(Lazyload);
app.use(Tab);
app.use(Tabs);
app.use(NavBar);
app.use(BackTop);
app.use(Loading);
app.use(Overlay);
// app.use(vConsole as any);
// app.use(<any>vConsole);
app.component("WapInter", WapInter);
app.component("WapNative", WapNative);
app.component("WapBanner", WapBanner);
app.component("MyLoading", loading);
app.component("ICP", ICP);
app.use(store);
app.use(router);
const cfg = {
  queryMap: {
    // query的key
    channel: "channel",
    subchannel: "subchannel",
    key: "key"
  },
  spaceMap: SPACE_MAP, // 广告位配置
  router: router, // router实例
  home: "Home", // 路由首页的name，对于首页有多个子页面的，可以传数组['Home','Analysis']
  app: app, // 应用实例
  deepStart: process.env.VUE_APP_START == "guide" ? 1 : 2
};
Haa.init(cfg); // sdk

app.mount("#app");
