<template>
  <router-view />
</template>

<style lang="less">
@font-face {
  font-family: "myfont";
  src: url("https://static.zookingsoft.com/poet_app/img/FZKTJW.TTF");
  font-weight: normal;
  font-style: normal;
}
div {
  font-family: "myfont";
}
</style>
