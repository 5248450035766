/*
 * @Date: 2023-06-19 14:19:20
 */
const INTER = [{ space_src: "baidujs", space_type: "inters", space_id: "u6915517", space_js: "//afzbdm.moddydata.com/source/mhg-pqe/static/e/qi.js" }];
const HOMEDIALOG = [
  { space_src: "baidujs", space_type: "native", space_id: "u6915518", space_js: "//afzbdm.moddydata.com/source/n-i/static/hq/openjs/rffr-k.js" }
];
const CENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6915519",
    space_js: "//afzbdm.moddydata.com/production/o/resource/j/common/i_r/production/sggsr.js"
  }
];
const BANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6915520", space_js: "//afzbdm.moddydata.com/common/gba/jkyy_q/h.js" }];
const POETCENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6915519",
    space_js: "//afzbdm.moddydata.com/production/o/resource/j/common/i_r/production/sggsr.js"
  }
];
const POETBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6915520", space_js: "//afzbdm.moddydata.com/common/gba/jkyy_q/h.js" }];
const POEMCENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6915519",
    space_js: "//afzbdm.moddydata.com/production/o/resource/j/common/i_r/production/sggsr.js"
  }
];
const POEMBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6915520", space_js: "//afzbdm.moddydata.com/common/gba/jkyy_q/h.js" }];
const POEMINTER = [{ space_src: "baidujs", space_type: "inters", space_id: "u6915517", space_js: "//afzbdm.moddydata.com/source/mhg-pqe/static/e/qi.js" }];
const POETLISTCENTER = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6915519",
    space_js: "//afzbdm.moddydata.com/production/o/resource/j/common/i_r/production/sggsr.js"
  }
];
const POETLISTBANNER = [{ space_src: "baidujs", space_type: "native", space_id: "u6915520", space_js: "//afzbdm.moddydata.com/common/gba/jkyy_q/h.js" }];

/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: INTER, // 插屏
  Home_dialog: HOMEDIALOG, // 自定义插屏
  Home_top: CENTER, // 首页信息流
  Home_banner: BANNER, // 首页Banner
  Poet_center: POETCENTER, // 诗人详情信息流
  Poet_banner: POETBANNER, // 诗人详情banner
  Poem_center: POEMCENTER, // 诗词详情信息流
  Poem_banner: POEMBANNER, // 诗词详情banner
  Poem_inter: POEMINTER, // 诗词详情官方插屏
  PoetList_center: POETLISTCENTER, // 诗人列表信息流
  PoetList_banner: POETLISTBANNER // 诗人列表banner
};
