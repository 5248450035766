/*
 * @Date: 2023-06-19 14:19:20
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getUrlParams } from "@/utils/common";
const params: AnyObject = getUrlParams(window.location.href);
const getRedirect = () => {
  if (process.env.VUE_APP_START == "index") return "/index";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else if (params.qapp) return "/guide";
  else return "/index";
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/index",
    name: "Index",
    redirect: "/pageMain",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      spaceKeys: ["Home_top", "Home_inter", "Home_banner"]
    },
    children: [
      {
        path: "/pageMain",
        name: "Home",
        component: () => import("@/views/home/pageMain.vue"),
        meta: {
          spaceKeys: ["Home_top", "Home_banner"]
        }
      },
      {
        path: "/pageHotPoetry",
        name: "PageHotPoetry",
        component: () => import("@/views/home/pageHotPoetry.vue"),
        meta: {
          spaceKeys: ["Home_top", "Home_banner"]
        }
      },
      {
        path: "/pagePavilion",
        name: "PagePavilion",
        component: () => import("@/views/home/pagePavilion.vue"),
        meta: {
          spaceKeys: ["Home_banner"]
        }
      }
    ]
  },
  {
    path: "/guide", // 启动页
    name: "Guide",
    component: () => import("@/views/guideView.vue"),
    meta: {
      spaceKeys: ["Guide_native", "Guide_banner"]
    }
  },
  // {
  //   path: "/poetDList", // 诗人列表
  //   name: "PoetList",
  //   component: () => import("@/views/poet/PoetList.vue")
  // },
  {
    path: "/poetDateils", // 诗人详情
    name: "PoetDateils",
    component: () => import("@/views/poet/poetDetails.vue"),
    meta: {
      spaceKeys: ["Poet_center", "Poet_banner"]
    }
  },
  {
    path: "/dynastyPoet", // 朝代诗人
    name: "DynastyPoet",
    component: () => import("@/views/poet/dynastyPoet.vue"),
    meta: {
      spaceKeys: ["PoetList_center", "PoetList_banner"]
    }
  },
  {
    path: "/poetryDateils", // 诗详情
    name: "PoetryDateils",
    component: () => import("@/views/poetry/details.vue"),
    meta: {
      spaceKeys: ["Poem_center", "Poem_inter", "Poem_banner"]
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});
// const router = createRouter({
//   history: createWebHashHistory(),
//   routes,
//   // 每次切换路由页面滚动到顶部
//   scrollBehavior() {
//     return { top: 0 };
//   }
// });
/**\const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 0);
    });
  },
  routes
}); */

// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0);
//   next();
// });
export default router;
