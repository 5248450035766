export const getUrlParams = (url: string) => {
  url = url || window.location.toString();
  const paramStr = url.split("?")[1];
  if (!paramStr) return {};
  const kavs = paramStr.split("&");
  const result: AnyObject = {};
  kavs.forEach((item) => {
    const kv = item.split("=");
    result[kv[0]] = kv[1].replace("#/", "");
  });
  return result;
};

export const randNum = (randNum: number, scopeNum: number) => {
  const num = [];
  for (let i = 0; i < randNum; i++) {
    num[i] = Math.floor(Math.random() * scopeNum);
    for (let j = 0; j < i; j++) {
      if (num[i] == num[j]) {
        i--;
      }
    }
  }
  return num;
};
